import React from 'react';
import {Auth} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {  Navigate } from 'react-router-dom';

export function Logout(user, userEmail) {
  if (userEmail !== "" || userEmail !== undefined || userEmail !== null) {
    console.log("Logging out");
    Auth.signOut();
    console.log("User Logged out");
    return (
      <>
        <Navigate to="/home" replace />
      </>
    );
    } 
  console.log("User Not Logged out");
}

export default Logout;