/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Authenticator, Flex, withAuthenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { redirectDocument } from 'react-router';
import { Home } from './Home';
import { AskVivek } from './AskVivek';
import { Chat } from './Chat';
import { About } from './About';
import { Contact } from './Contact';
import { Login } from './Login';
import { Logout } from './Logout';
import { AddQuestionType } from './AddQuestionType';
import './index.scss';

function removeTrailingSlash(path) {

  if (path.endsWith('/') && path.length > 1) {
    return path.slice(0, -1);
  }
  return path;
}



const MyRoutes = ()=> {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/home/" element={<Home />} />
        <Route path="/chat/" element={<Chat />} />
        <Route path="/contact/" element={<Contact />} />
        <Route path="/about/" element={<About />} />
        <Route path="/signin/" element={<Login initialState="signIn" fromPath='/'/>} />
        <Route path="/signup/" element={<Login initialState="signUp" fromPath='/'/>} />
        <Route path="*" element={<Home />} />
        <Route path="/askvivek/" element={<AskVivek /> } />
        <Route path="/questiontype/" element={<AddQuestionType />} />
        <Route path="/signout/" element={<Logout />} />
      </Routes>
    </>
  );
}

const pageTheme = {
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  FontFace: {
    fontFamily: "Roboto",
    src: "https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"
  }
}

const App = function () {
  


  return (
    <Flex direction="column" width="1440px" margin="0 auto" theme={pageTheme}>
      <>
        <BrowserRouter basename={process.env.PUBLIC_URL} >
          <MyRoutes  />
        </BrowserRouter>
      </>
    </Flex>
  );
};


export default App;