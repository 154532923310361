/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserMessages = /* GraphQL */ `
  mutation CreateUserMessages(
    $input: CreateUserMessagesInput!
    $condition: ModelUserMessagesConditionInput
  ) {
    createUserMessages(input: $input, condition: $condition) {
      id
      message
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      userMessagesUserId
      __typename
    }
  }
`;
export const updateUserMessages = /* GraphQL */ `
  mutation UpdateUserMessages(
    $input: UpdateUserMessagesInput!
    $condition: ModelUserMessagesConditionInput
  ) {
    updateUserMessages(input: $input, condition: $condition) {
      id
      message
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      userMessagesUserId
      __typename
    }
  }
`;
export const deleteUserMessages = /* GraphQL */ `
  mutation DeleteUserMessages(
    $input: DeleteUserMessagesInput!
    $condition: ModelUserMessagesConditionInput
  ) {
    deleteUserMessages(input: $input, condition: $condition) {
      id
      message
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      userMessagesUserId
      __typename
    }
  }
`;
export const createQuestionType = /* GraphQL */ `
  mutation CreateQuestionType(
    $input: CreateQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    createQuestionType(input: $input, condition: $condition) {
      id
      qType
      qTypeDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestionType = /* GraphQL */ `
  mutation UpdateQuestionType(
    $input: UpdateQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    updateQuestionType(input: $input, condition: $condition) {
      id
      qType
      qTypeDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestionType = /* GraphQL */ `
  mutation DeleteQuestionType(
    $input: DeleteQuestionTypeInput!
    $condition: ModelQuestionTypeConditionInput
  ) {
    deleteQuestionType(input: $input, condition: $condition) {
      id
      qType
      qTypeDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createVoterType = /* GraphQL */ `
  mutation CreateVoterType(
    $input: CreateVoterTypeInput!
    $condition: ModelVoterTypeConditionInput
  ) {
    createVoterType(input: $input, condition: $condition) {
      id
      voterType
      voterDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVoterType = /* GraphQL */ `
  mutation UpdateVoterType(
    $input: UpdateVoterTypeInput!
    $condition: ModelVoterTypeConditionInput
  ) {
    updateVoterType(input: $input, condition: $condition) {
      id
      voterType
      voterDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVoterType = /* GraphQL */ `
  mutation DeleteVoterType(
    $input: DeleteVoterTypeInput!
    $condition: ModelVoterTypeConditionInput
  ) {
    deleteVoterType(input: $input, condition: $condition) {
      id
      voterType
      voterDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      locationName
      stateNames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      locationName
      stateNames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      locationName
      stateNames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReference = /* GraphQL */ `
  mutation CreateReference(
    $input: CreateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    createReference(input: $input, condition: $condition) {
      id
      refName
      refUrl
      refDesc
      refType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReference = /* GraphQL */ `
  mutation UpdateReference(
    $input: UpdateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    updateReference(input: $input, condition: $condition) {
      id
      refName
      refUrl
      refDesc
      refType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReference = /* GraphQL */ `
  mutation DeleteReference(
    $input: DeleteReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    deleteReference(input: $input, condition: $condition) {
      id
      refName
      refUrl
      refDesc
      refType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      addedBy
      questionType {
        id
        qType
        qTypeDesc
        createdAt
        updatedAt
        __typename
      }
      voterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      reference {
        id
        refName
        refUrl
        refDesc
        refType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionQuestionTypeId
      questionVoterTypeId
      questionLocationId
      questionReferenceId
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      addedBy
      questionType {
        id
        qType
        qTypeDesc
        createdAt
        updatedAt
        __typename
      }
      voterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      reference {
        id
        refName
        refUrl
        refDesc
        refType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionQuestionTypeId
      questionVoterTypeId
      questionLocationId
      questionReferenceId
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      addedBy
      questionType {
        id
        qType
        qTypeDesc
        createdAt
        updatedAt
        __typename
      }
      voterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      reference {
        id
        refName
        refUrl
        refDesc
        refType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionQuestionTypeId
      questionVoterTypeId
      questionLocationId
      questionReferenceId
      __typename
    }
  }
`;
export const createResponses = /* GraphQL */ `
  mutation CreateResponses(
    $input: CreateResponsesInput!
    $condition: ModelResponsesConditionInput
  ) {
    createResponses(input: $input, condition: $condition) {
      id
      response
      responseDate
      Question {
        id
        question
        answer
        addedBy
        createdAt
        updatedAt
        questionQuestionTypeId
        questionVoterTypeId
        questionLocationId
        questionReferenceId
        __typename
      }
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      responsesQuestionId
      responsesUserId
      __typename
    }
  }
`;
export const updateResponses = /* GraphQL */ `
  mutation UpdateResponses(
    $input: UpdateResponsesInput!
    $condition: ModelResponsesConditionInput
  ) {
    updateResponses(input: $input, condition: $condition) {
      id
      response
      responseDate
      Question {
        id
        question
        answer
        addedBy
        createdAt
        updatedAt
        questionQuestionTypeId
        questionVoterTypeId
        questionLocationId
        questionReferenceId
        __typename
      }
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      responsesQuestionId
      responsesUserId
      __typename
    }
  }
`;
export const deleteResponses = /* GraphQL */ `
  mutation DeleteResponses(
    $input: DeleteResponsesInput!
    $condition: ModelResponsesConditionInput
  ) {
    deleteResponses(input: $input, condition: $condition) {
      id
      response
      responseDate
      Question {
        id
        question
        answer
        addedBy
        createdAt
        updatedAt
        questionQuestionTypeId
        questionVoterTypeId
        questionLocationId
        questionReferenceId
        __typename
      }
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      responsesQuestionId
      responsesUserId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      VoterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      Location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userVoterTypeId
      userLocationId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      VoterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      Location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userVoterTypeId
      userLocationId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      VoterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      Location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userVoterTypeId
      userLocationId
      __typename
    }
  }
`;
