/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text, View } from "@aws-amplify/ui-react";
export default function HomePage(props) {
  const { overrides, ...rest } = props;
  const getStartedOnClick = useNavigateAction({ type: "url", url: "/signup/" });
  const labelOnClick = useNavigateAction({ type: "url", url: "" });
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(13,26,38,1)"
      {...getOverrideProps(overrides, "HomePage")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="650px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Frame 437")}
      >
        <Flex
          gap="0"
          direction="column"
          width="935px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="70px 0px 70px 0px"
          backgroundColor="rgba(13,26,38,1)"
          {...getOverrideProps(overrides, "Left")}
        >
          <Flex
            gap="17px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 17px 0px 17px"
            {...getOverrideProps(overrides, "HeroMessage")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="454px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Message")}
            >
              <Text
                fontFamily="Roboto"
                fontSize="32px"
                fontWeight="700"
                color="rgba(245,188,188,1)"
                lineHeight="24px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Ask Vivek Ramaswamy"
                {...getOverrideProps(overrides, "Eyebrow")}
              ></Text>
              <Image
                width="814px"
                height="90px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="contain"
                src="/TTD_Text.png"
                {...getOverrideProps(overrides, "TTD_Text 1")}
              ></Image>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="798px"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Imagine a world where voters can ask any question they want, and get a factual answer without any personal opinions, judgments, or biases. That's the future we're building at Vivek-GPT. We're a team of experts in artificial intelligence who are passionate about democracy. We believe that voters deserve access to accurate, unbiased information so they can make informed decisions."
                {...getOverrideProps(overrides, "Body38623484")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="798px"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="We created Vivek-GPT, a large language model that can answer any question you have about the issues that matter most. Vivek-GPT is always learning and improving, and we're committed to providing you with the most reliable and factual information possible. We're revolutionizing the way candidates and voters communicate with each other."
                {...getOverrideProps(overrides, "Body39002180")}
              ></Text>
              <Text
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="400"
                color="rgba(255,255,255,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Sign up today and join the revolution!"
                {...getOverrideProps(overrides, "Body39002181")}
              ></Text>
            </Flex>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Get started"
              onClick={() => {
                getStartedOnClick();
              }}
              {...getOverrideProps(overrides, "GetStarted")}
            ></Button>
          </Flex>
        </Flex>
        <View
          width="unset"
          height="650px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Right")}
        >
          <Image
            width="505px"
            height="650px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="/VivekAnswering.jpg"
            {...getOverrideProps(overrides, "HomeImage")}
          ></Image>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-end"
            position="absolute"
            bottom="74px"
            left="98px"
            borderRadius="4px"
            padding="8px 16px 8px 16px"
            backgroundColor="rgba(37,9,206,0.96)"
            {...getOverrideProps(overrides, "Donate")}
          >
            <Text
              fontFamily="Inter"
              fontSize="20px"
              fontWeight="700"
              color="rgba(255,255,255,1)"
              lineHeight="30px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Donate To Vivek’s Campaign"
              onClick={() => {
                labelOnClick();
              }}
              {...getOverrideProps(overrides, "label")}
            ></Text>
          </Flex>
        </View>
      </Flex>
    </Flex>
  );
}
