/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, SelectField, Text, Image } from "@aws-amplify/ui-react";
import FilterField from "./FilterField";
import QuestionAnswer from "../ui-components/QuestionAnswer";
import AutocompleteField from "./AutocompleteField";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations.js";
import awsconfig from "../aws-exports";
import { API, graphqlOperation } from "aws-amplify";

API.configure(awsconfig);

const fetchLocations = async () => {
    const result = await API.graphql({
        query: queries.listLocations,
        variables: { limit: 12, items: [] },
    });
    const items = result.data?.listLocations?.items;
    const options = items?.map((rec) => {
        return ({ key: rec.id, value: rec.locationName, title: rec.stateNames })
    })
    return options
};

const fetchQTypes = async () => {
    const result = await API.graphql({
        query: queries.listQuestionTypes,
        variables: { limit: 12, items: [] },
    });
    const items = result.data?.listQuestionTypes?.items;
    const options = items?.map((rec) => {
        return ({ key: rec.id, value: rec.qType, title: rec.qTypeDesc })
    })
    return options
};

const fetchVTypes = async () => {
    const result = await API.graphql({
        query: queries.listVoterTypes,
        variables: { limit: 12, items: [] },
    })
    const items = result.data?.listVoterTypes?.items;
    const options = items?.map((rec) => {
        return ({ key: rec.id, value: rec.voterType, title: rec.voterDescription })
    })
    return options
};

export const getUser = async (email) => {

    if (email === undefined) return undefined;
    let user = undefined
    try {
        const uData = await API.graphql(
            graphqlOperation(queries.listUsers, {
                filter: { email: { eq: email } }
            })
        );
        user = uData?.data?.listUsers.items[0];
    } catch (err) {
        // console.log(`Error getting user list ${JSON.stringify(err)}`);
    }
    // console.log(`getUser: ${JSON.stringify(user)}`);
    return user
}

const addUser = async (name, email, vTypeId, locationId, userid) => {
    if (userid === undefined) {
        const userInput = {
            email: email,
            name: name,
            userVoterTypeId: vTypeId,
            userLocationId: locationId
        };

        const user = await API.graphql({
            query: mutations.createUser,
            variables: { input: userInput },
        }).then((uData) => {
            // console.log(`User added: ${uData}`);
            return uData?.data?.createUser
        }).catch((err) => {
            // console.log(`Error adding User: ${JSON.stringify(err)}`);
        });
        return user;
    }
    return userid;
};

export const getResponse = async (qid, uid) => {

    if (qid === undefined || uid === undefined) return undefined;

    const filterValues = {}

    if (qid !== undefined) filterValues.responsesQuestionId = { eq: qid }
    if (uid !== undefined) filterValues.responsesUserId = { eq: uid }
    // console.log("FilterValues: ", filterValues)
    const response = await API.graphql(
        graphqlOperation(queries.listResponses, {
            filter: filterValues
        })
    ).then(rData => {
        // console.log(`Getting Response: ${JSON.stringify(rData)}`);
        return rData?.data?.listResponses?.items[0]
    }).catch((err) => {
        // console.log(`Error getting response: ${JSON.stringify(err)}`);
        return undefined;
    });
    return response;
}

const addResponse = async (questionId, userId, vote, responseId) => {
    let response = undefined;
    // console.log(`addResponse: ${questionId} ${userId} ${vote} ${responseId}`)
    const responseInput = {
        response: vote,
        responseDate: new Date().toISOString(),
        responsesQuestionId: questionId,
        responsesUserId: userId
    };

    if (responseId !== undefined) {
        responseInput.id = responseId;
        let resp = undefined
        const result = await API.graphql({
            query: mutations.updateResponses,
            variables: { input: responseInput }
        }).then((rData) => {
            // console.log(`Response updated : ${JSON.stringify(rData)}`);
            resp = rData?.data?.updateResponses
            return resp;
        }).catch((err) => {
            // console.log(`Error updating response: ${JSON.stringify(err)}`);
            resp = responseInput;
            return resp;
        });
        // console.log(`Response updated : ${JSON.stringify(result)}`);
        return result;
    } else {
        const resp = await API.graphql({
            query: mutations.createResponses,
            variables: { input: responseInput },
        }).then((rData) => {
            // console.log(`Response added: ${rData}`);
            response = rData?.data?.createResponses
            return response
        }).catch((err) => {
            // console.log(`Error adding response: ${JSON.stringify(err)}`);
            response = undefined
            return response;
        });
        console
        return resp;
    }
};



const fetchQuestions = async (qTypeID, vTypeID, locationID) => {
    // console.log(`fetchQuestions: qTypeID: ${qTypeID}, vTypeID: ${vTypeID}, locationID: ${locationID}`)
    let filter = {};
    if (qTypeID !== '6cb4da5d-2146-460b-a0f8-6a0c0db08cf9')
        filter.questionQuestionTypeId = { eq: qTypeID };
    if (vTypeID !== 'b15892b3-2ab7-49bd-8534-50dd06ec380b')
        filter.questionVoterTypeId = { eq: vTypeID };
    if (locationID !== 'd9ae739f-1ccf-423d-b518-2bd08f3ef86f')
        filter.questionLocationId = { eq: locationID };

    const myQuery = `query ListQuestions {
        listQuestions {
          items {
            id
            question
            answer
            addedBy
            questionLocationId
            questionQuestionTypeId
            questionVoterTypeId
            updatedAt
            questionReferenceId
            reference {
              id
              refName
              refDesc
              refUrl
              refType
            }
          }
        }
      }`
    // console.log(filter)
    const result = await API.graphql({
        query: myQuery,
        variables: {
            limit: 50,
            items: [],
            filter: filter
        },
    });
    return result.data?.listQuestions?.items;
};

export function GetLink(url) {
    if (url === undefined) return undefined;
    return <link rel="stylesheet" href={url} />;
}


export default function AskVivekPage(props) {
    const [locations, setLocations] = React.useState([]);
    const [vTypes, setVTypes] = React.useState([]);
    const [qTypes, setQTypes] = React.useState([]);

    const [questions, setQuestions] = React.useState([]);
    const [qopts, setQopts] = React.useState([]);

    const [vTypeID, setVTypeID] = React.useState();
    const [locationID, setLocationID] = React.useState();
    const [qTypeID, setQTypeID] = React.useState();

    const [vType, setVType] = React.useState('All voters');
    const [location, setLocation] = React.useState('All');
    const [qType, setQType] = React.useState('General');

    const [questionID, setQuestionID] = React.useState();
    const [question, setQuestion] = React.useState();
    const [answer, setAnswer] = React.useState();
    const [addedBy, setAddedBy] = React.useState();
    const [reference, setReference] = React.useState();
    const [userEmail, setUserEmail] = React.useState(props.user.attributes.email);
    const [userName, setUserName] = React.useState(props.user.attributes.name);
    const [userid, setUserid] = React.useState();
    const [responseId, setResponseId] = React.useState();
    const [vote, setVote] = React.useState();
    const [addedOn, setAddedOn] = React.useState();



    // console.log(`AskVivekPage: ${props.user.attributes.name} ${props.user.username}`)
    const setCurrentQuestion = async (index) => {
        //console.log(questions);
        // console.log(`setCurrentQuestion: ${index} UserId: ${userid}`);
        setResponseId(undefined);
        setVote(undefined);
        const q = questions[index];
        if (q !== undefined) {
            // console.log(`setCurrentQuestion: ${Object.entries(q)}`);
            //      // console.log(`Question Id : ${q["id"]}`);
            setQuestionID(q["id"]);
            setQuestion(q["question"]);
            setAnswer(q["answer"]);
            setAddedBy(q["addedBy"]);
            setAddedOn(new Date(q?.updatedAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }));
            setReference(q["reference"]?.refUrl);
            getResponse(q["id"], userid).then((r) => { setResponseId(r?.id); setVote(r?.response) }).catch((err) => { console.log(err) });
        }
        return (q)

    }

    useEffect(() => {
        getUser(userEmail).then(async (u) => {
            if (u === undefined) {
                await addUser(props.user.attributes.name, props.user.attributes.email, vTypeID, locationID)
                    .then((u) => { setUserid(u.id) })
                    .catch((err) => { console.log(JSON.stringify(err)) });
            } else {
                // console.log(`User found: ${JSON.stringify(u)}`);
                setUserid(u.id);
            }
        }).catch((err) => { console.log(JSON.parse.stringify(err)) });

        fetchLocations().then((recs) => { setLocations(recs); }).catch((err) => { console.log(err) });
        fetchVTypes().then((recs) => { setVTypes(recs); }).catch((err) => { console.log(err) });
        fetchQTypes().then((recs) => { setQTypes(recs); }).catch((err) => { console.log(err) });
    }, []);

    useEffect(() => {
        // console.log('useEffect: fetchQuestions')
        //if (qTypeID !== undefined && vTypeID !== undefined && locationID !== undefined) 
        fetchQuestions(qTypeID, vTypeID, locationID).then((recs) => { setQuestions(recs); }).catch((err) => { console.log(err) });
    }, [qTypeID, vTypeID, locationID]);


    useEffect(() => {
        if (questions.length > 0) {
            questions.find((rec, index) => {
                if (rec.id === questionID) {
                    setCurrentQuestion(index);
                    return true;
                }
            })
        }
    }, [questionID]);

    useEffect(() => {
        const options = questions?.map((rec) => {
            return ({ id: rec.id, key: rec.question, title: rec.answer })
        })
        // console.log('Number of questions: ' + options.length);
        setQopts(options);
    }, [questions]);


    const handleQuestionSelect = (id, key, title) => {
        // console.log("ABC:", id, key, title)
        // console.log(`in AskVivek Page: Questions: ${questions}`);
        const q = questions.find((obj) => obj['id'] === id)
        // console.log(`q =  ${JSON.stringify(q)}`)
        console.log(`in AskVivek Page:
    question id: ${id}
    question: ${q?.question}
    answer: ${q?.answer}
    addedBy: ${q?.addedBy}
    addedOn: ${new Date(q?.updatedAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
    reference: ${q?.reference?.refUrl}`);
        setQuestionID(q?.id)
        setQuestion(q?.question)
        setAnswer(q?.answer)
        setAddedBy(q?.addedBy)
        setAddedOn(new Date(q?.updatedAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }))
        setReference(q?.reference?.refUrl)
    }

    const handleClear = () => {
        // console.log(`in AskVivek Page: Clearing question`);
        setQuestionID(undefined)
        setQuestion(undefined)
        setAnswer(undefined)
        setAddedBy(undefined)
        setAddedOn(undefined)
        setReference(undefined)
        setResponseId(undefined)
        setVote(undefined)
    }


    const handleAudienceSelect = (vTypeId) => {
        const vType = vTypes?.find((rec) => rec.key === vTypeId)?.value;
        console.log(`In AskVivek Page voter type select:
                vTypeId: ${vTypeId}
                vType: ${vTypes?.find((rec) => rec.key === vTypeId).value}`);
        setVTypeID(vTypeId);
        setVType(vType);
    }

    const handleLocationSelect = (locationID) => {
        const loc = locations.find((rec) => rec.key === locationID);
        console.log(`in AskVivek Page location select:
                loc id: ${locationID}
                loc name: ${locations.find((rec) => rec.key === locationID)?.value}`);
        setLocationID(locationID);
        setLocation(loc.value);
    }

    const handleTopicSelect = (qTypeId) => {
        const qType = qTypes.find((rec) => rec.key === qTypeId).value;
        console.log(`in AskVivek Page topic select 
                qTypeId: ${qTypeId} 
               qType: ${qTypes.find((rec) => rec.key === qTypeId)?.value}`);
        setQTypeID(qTypeId);
        setQType(qType);
    }

    const handleVoteUp = () => {
        setVote('UP')
        // console.log(`Voted ${vote}  ${questionID} ${userid}  ${responseId}`);
        if (questionID !== undefined && userid !== undefined) {
            const resp = addResponse(questionID, userid, 'UP', responseId)
                .then((r) => {
                    setResponseId(r?.id);
                    setVote(r?.response)
                    return r;
                }).catch((err) => { console.log(err) });
            // console.log(`Response added: ${JSON.stringify(resp)}`);
        }

    }

    const handleVoteDown = () => {
        setVote('DOWN')
        // console.log(`Voted 'DOWN'  ${questionID} ${userid}  ${responseId}`);
        if (questionID !== undefined && userid !== undefined) {
            const resp = addResponse(questionID, userid, 'DOWN', responseId)
                .then((r) => {
                    setResponseId(r?.id);
                    setVote(r?.response)
                    return r;
                }).catch((err) => { console.log(err) });
            // console.log(`Response added: ${JSON.stringify(resp)}`);
        }
    }

    const { overrides, ...rest } = props;
    return (
        <Flex
            gap="0"
            direction="column"
            width="1440px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            style={{ backgroundImage: "url('/townhall.jpg')" }}
        >
            <Flex
                gap="39px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="3px SOLID rgba(0,0,0,1)"
                padding="44px 77px 13px 47px"
                {...getOverrideProps(overrides, "QAContainer")}
            >
                <Flex
                    gap="186px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(255,255,255,0.96)"
                    {...getOverrideProps(overrides, "Filters")}
                >
                    <FilterField name='questionType' label="Topic" options={qTypes} onSelect={handleTopicSelect} defaultValue='General' />
                    <FilterField name='voterType' label="Audience" options={vTypes} onSelect={handleAudienceSelect} defaultValue='All voters' />
                    <FilterField name='locationNames' label="Location" options={locations} onSelect={handleLocationSelect} defaultValue='All' />
                </Flex>
                <Flex
                    gap="180px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="left"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    backgroundColor="rgba(255,255,255,0.96)"
                    {...getOverrideProps(overrides, "Filters")}
                >
                    <AutocompleteField
                        onSelect={handleQuestionSelect}
                        onClear={handleClear}
                        backgroundColor="rgba(255,255,255,0.50)"
                        options={qopts}
                        alignSelf="stretch"
                        width="100%"
                        name="Questions"
                        label="Find your Question"
                        placeholder="Type a keyword and select from the list"
                        className="my-autocomplete"
                    /></Flex>
                <QuestionAnswer
                    display="flex"
                    gap="16px"
                    direction="column"
                    width="unset"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="24px 24px 24px 24px"
                    backgroundColor="rgba(255,255,255,0.96)"
                    overrides={{
                        AuthorName: { children: addedBy },
                        AnswerDate: { children: addedOn, fontFamily: "Arial", fontSize: "16px" },
                        QuestionText: { children: question, fontFamily: "Arial", fontSize: "20px" },
                        AnswerText: { children: answer, fontFamily: "Arial", fontSize: "20px" },
                        ReferenceText: {
                            children: ["Reference :      ", `${reference}`], fontWeight: "semibold", fontFamily: "Arial", fontSize: "18px",
                            onClick: () => { window.open(reference, "_blank") }
                        },
                        "Read more29766870": { display: "none" },
                        MyIcon29766869: { display: "none" },
                        image: { display: "none" },
                    }}
                    {...getOverrideProps(overrides, "QuestionAnswer")}
                >
                </QuestionAnswer>
                <Flex
                    gap="31px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="center"
                    alignItems="center"
                    shrink="0"
                    position="relative"
                    padding="13px 11px 13px 11px"
                    {...getOverrideProps(overrides, "Responses")}
                >
                    <Button onClick={handleVoteUp}
                        width="73px"
                        height="unset"
                        shrink="0"
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                        backgroundColor={vote === 'UP' ? "rgb(39, 221, 93)" : "rgb(201, 211, 210)"}
                        size="small"
                        isDisabled={false}
                        variation="default"
                        children="Up"
                        {...getOverrideProps(overrides, "UpButton")}
                    ></Button>
                    <Button onClick={handleVoteDown}
                        width="73px"
                        height="unset"
                        shrink="0"
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                        backgroundColor={vote === 'DOWN' ? "rgb(247, 50, 50)" : "rgb(201, 211, 210)"}
                        size="small"
                        isDisabled={false}
                        variation="default"
                        children="Down"
                        {...getOverrideProps(overrides, "DownButton")}
                    ></Button>
                </Flex>
            </Flex>
            <Text
                fontSize={["20px"]}
                backgroundColor="rgba(246, 248, 211, 0.8)"
                color="rgba(35,35,35,1)">
                Answering a {qType} question for {vType} in {location} states.
            </Text>
            <Text
                fontSize={["24px"]}
                backgroundColor="rgb(98,98,98,.85)"
                color="rgba(240,240,240,1)">
                Note*: Not affiliated with Mr. Ramaswamy's campaign, however, we represent his views
                accuarately and consistently.
            </Text>
        </Flex>
    );
}
