import React from 'react';
import { AboutPage } from '../ui-components/index';
import { VGPTNavBar}  from './VGPTNavBar';
import { Authenticator } from '@aws-amplify/ui-react';
export function About() {
 
  return (
    <>
    <Authenticator.Provider>
      <VGPTNavBar />
    </Authenticator.Provider>
    <AboutPage/> 
    </>);
}