import React from "react";
import { Authenticator, View, Flex,  useTheme, Text } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useLocation, Navigate } from "react-router-dom";
import { VGPTNavBar } from "./VGPTNavBar";
import PropTypes from "prop-types";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color="darkred"  fontSize='1.5em' fontWeight="bold"
            backgroundColor='#f5e5e5'>
          Welcome to Vivek&apos;s Online Townhall 
        </Text>
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },
};
export const Login = ({ initialState, fromPath }) => {
  const location = useLocation();

  console.log(`initialState: ${initialState}`);
  console.log(`location: ${location.pathname}`)
  return (
    <>
      <Authenticator.Provider>
        <VGPTNavBar />
      </Authenticator.Provider>
    <Flex style={{ 
      backgroundColor: '#bb1010',
      backgroundImage: 'url(/townhall.jpg)',
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      height: '700px',
      alignItems: 'center',
      justifyContent: 'center',
   }}>  <Authenticator components={components}
        signUpAttributes={["name", "email"]}
        initialState={initialState}
        hideSignUp={false}
        hideForgotPassword={false}
        hideConfirmSignUp={true}
        hideSignIn={true}
        hideSignOut={false}
        hideOAuth={true}
        hideOAuthMessage={true}
        hideLoading={false}
      >
        {({ signOut, user }) => <Navigate to="/fromPath" />
        }
      </Authenticator>
    </Flex>
    </>
  );
};

Login.propTypes = {
  initialState: PropTypes.string.isRequired,
};

export default Login;
