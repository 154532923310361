/* eslint-disable no-unreachable */
import React, {useEffect} from 'react';
import { API } from 'aws-amplify';
import { ContactPage } from '../ui-components/';
import { VGPTNavBar } from './VGPTNavBar';
import { Login } from './Login';
import * as mutations from '../graphql/mutations';
import { Text,  Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Navigate } from 'react-router-dom';

const saveMessage = async (message, userid) => {
  let msg = undefined;

  const resp = await API.graphql({
    query: mutations.createUserMessages,
    variables: { input: { message: message, userid: userid } },
  }).then((rData) => {
    console.log(`Response added: ${rData}`);
    msg = rData?.data?.createUserMessages
    return msg
  }).catch((err) => {
    console.log(`Error adding response: ${JSON.stringify(err)}`);
    msg = undefined
    return msg;
  });
  return resp;
}

export const Contact = () => {
    const { user } = useAuthenticator();
    const userEmail = user?.attributes?.email

    console.log(userEmail, user)
    if(userEmail === "" | userEmail === undefined | userEmail === null)
        return (
            <>
                <Login initialState="signin" fromPath="/contact/" />
            </>)

    return (
        <>
            <ContactWithLogin user={user} userEmail={userEmail} />
        </>
    )
}

export function ContactWithLogin(props) {
  
  const [user, setUser] = React.useState(props?.user);
  const [userEmail, setUserEmail] = React.useState(props?.userEmail)
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [checkFlag, setCheckFlag] = React.useState(false);
  const [userAlert, setUserAlert] = React.useState(''); 
  const [userid, setUserid] = React.useState('');

  
  console.log(userEmail, user)

  const userName = user?.attributes?.name
  console.log('Email: ', userEmail, "Name: ", userName, "\n", user)

  let fn, ln;

  useEffect(() => {
    setFirstName(fn);
    setLastName(ln);
    if (user?.attributes?.email !== undefined)
      setUserEmail(user.attributes.email);
    if (user?.attributes?.sub !== undefined)
      setUserid(user.attributes.sub);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fn, ln, userEmail])

  if (userName !== undefined) 
    if (userName.includes(",")===true)  
      [ln, fn] = userName.split(",");
    else
      [fn, ln] = userName.split(" ");
  

  const  handleClick = () => {
    console.log("Send Button Clicked", checkFlag, message, userid);
    if (checkFlag === false) {
      setUserAlert("Privacy confirmation and Message are required");
      //alert("Please check the box to confirm our privacy policy");
      return
    }
    if (message === '') {
      setUserAlert("Please enter your message");
      //alert("Please enter your message");
      return
    }

    const msg = saveMessage(message, user.id);
    console.log("Message: ", msg)
    if(msg === undefined)
        setUserAlert("Error sending message. Please try again later");
    else
        setUserAlert("Your message has been sent.");
  }

  const hadleMessageChanges = (e) => {
    setUserAlert('');
    console.log("Message: ", e.target.value);
    setMessage(e.target.value);
  } 

  const handelCheckFlag = (e) => {
    setUserAlert('')
    setCheckFlag(e.target.checked);
  }

  return (
    <>
      <Authenticator.Provider>
        <VGPTNavBar />
      </Authenticator.Provider>
      <Text color="red" fontFamily= "Arial" fontSize="20px">{userAlert}</Text>
      <ContactPage
        overrides={{
          ContactUs : {
            overrides :   { 
              FirstName: { value: firstName,  readOnly: true, fontFamily: "Arial", fontSize: "16px" },
              LastName: { value: lastName, readOnly: true, fontFamily: "Arial", fontSize: "20px" },
              Email: { value: userEmail, readOnly: true, fontFamily: "Arial", fontSize: "20px" },
              Message: { placeholder: 'Plase share your thoughts', value: message, onChange: hadleMessageChanges, fontFamily: "Arial", fontSize: "20px" },
                SendButton: { onClick: handleClick, fontFamily: "Arial", fontSize: "20px" },
              CheckboxField: { value: checkFlag, onChange: handelCheckFlag, fontFamily: "Arial", fontSize: "14px" }
          }
          }
        }}
      />
    </>
  );

}
export default Contact;