/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Rating,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function ChatPage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ChatPage")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="1440px"
        height="590px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeroLayoutLeft")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="861px"
          height="583px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Left")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HeroMessage")}
          >
            <Flex
              gap="30px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Message")}
            >
              <Text
                fontFamily="Segoe UI"
                fontSize="32px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="60px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Question you may ask."
                {...getOverrideProps(overrides, "Heading")}
              ></Text>
              <Text
                fontFamily="Segoe UI"
                fontSize="24px"
                fontWeight="400"
                color="rgba(48,64,80,1)"
                lineHeight="32.5px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.21px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Who is Vivek Ramaswamy?&#xA;Why should I vote for him?&#xA;Will he win?&#xA;Where is he from?&#xA;What is his policy?&#xA;Can I trust Vivek Ramaswamy?"
                {...getOverrideProps(overrides, "Body")}
              ></Text>
              <Flex
                gap="9px"
                direction="row"
                width="unset"
                height="40px"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 19px 0px 19px"
                {...getOverrideProps(overrides, "Text")}
              >
                <TextField
                  width="unset"
                  height="unset"
                  grow="1"
                  shrink="1"
                  basis="0"
                  size="default"
                  isDisabled={false}
                  labelHidden={false}
                  variation="default"
                  {...getOverrideProps(overrides, "TextField")}
                ></TextField>
                <Icon
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  overflow="hidden"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  type="checkmark"
                  {...getOverrideProps(overrides, "\uD83D\uDD12Icon")}
                ></Icon>
              </Flex>
            </Flex>
          </Flex>
          <Rating
            width="unset"
            height="unset"
            justifyContent="center"
            shrink="0"
            size="large"
            {...getOverrideProps(overrides, "Rating")}
          ></Rating>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="580px"
          height="590px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Right")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="/raisedhand.jpeg"
            {...getOverrideProps(overrides, "image")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
