/* eslint-disable no-unused-vars */
import React, { useState} from 'react';
import HomePage  from '../ui-components/HomePage';
import Modal from 'react-modal';
import  { Authenticator, Flex, Text, Button}  from '@aws-amplify/ui-react';
import { VGPTNavBar } from './VGPTNavBar';
import {  useNavigateAction } from "@aws-amplify/ui-react/internal"
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

export function Home() {
  const homePageOnClick = useNavigateAction({ type: "url", url: "/signup" });
  const [isOpen, setIsOpen] = React.useState(false);

  const closeModal = () => {
      setIsOpen(false);
    };
  
  const handleDonateClick = () => {
    console.log('handleDonateClick');
    setIsOpen(true);
  }

  return (
    <Flex direction="column" width="100%" height="100%">
      <Modal isOpen={isOpen} onRequestClose={closeModal}
                ariaHideApp={false}>
        <iframe title="Donate to Vivek's Campaign" 
                closettimeoutms={1800}
                preventscroll="true"
                  src={'https://cabinet.vivek2024.com/donate/?pdgdb=64430d8f988dd_64ee3ffc80590108671218'} 
                width="100%" 
               height="99%" />
      </Modal>
      <Authenticator.Provider>
        <VGPTNavBar />
      </Authenticator.Provider>
      <HomePage overrides={{
        GetStarted: { onClick: homePageOnClick },
        Donate: { onClick: handleDonateClick },
        Body38623484 : {children: "Imagine a world where voters can ask any question they want, and get a factual answer without any personal opinions, judgments, or biases.  That's the future we're building at Vivek-GPT. We're a team of experts in artificial intelligence who are passionate about democracy. We believe that voters deserve access to accurate, unbiased information so they can make informed decisions."},
        Body39002180 : {children: ["That's why we created Vivek-GPT, a large language model that can answer any question you have about the issues that matter most. Vivek-GPT is always learning and improving, and we're committed to providing you with the most reliable and factual information possible. We're revolutionizing the way candidates and voters communicate with each other."]},
        Body39002181 : {children: "Sign up today and join the revolution! "}
      }}/>
        
      <Text
        fontSize="1.5rem"
        backgroundColor="rgba(230, 38, 38, 0.96)"
        color="white"
      >
        We are excited to announce the beta launch of Vivek-GPT on September 30th at OCGOP! We are constantly adding more and more questions. Check back often.
      </Text>
  
    </Flex>
  );
}
export default Home;