/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
import React, { useEffect } from "react";
import { View, Autocomplete, HighlightMatch, Divider, Link, ThemeProvider } from "@aws-amplify/ui-react";

const renderOptions = (option, value) => {
  return <HighlightMatch query={value}>{option?.key}</HighlightMatch>;
};

const AutocompleteField = (props) => {
  const [id, setId] = React.useState();
  const [key, setKey] = React.useState();
  const [label, setLabel] = React.useState();
  const [title, setTitle] = React.useState();
  const [value, setValue] = React.useState('');
  let ref = React.useRef(props?.ref);
  
/* This function sets the component props based on selected option */
const SetFieldValue = (id) => {
    const selectedOption = props?.options?.find((option) => option.id === id);
    if (selectedOption !== undefined) {
      setId(selectedOption.id);
      setKey(selectedOption.key);
      setTitle(selectedOption.title);
      setValue(selectedOption.key);
      props?.onSelect(selectedOption.id, selectedOption.key, selectedOption.title);
    }
  }

  /* This useEffect hook sets the component props based on the first option */
  useEffect(() => {
    if (props?.options?.length > 0)
        SetFieldValue(props?.options[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options]);

  const optionFilter = (option, value) => {
    // filter options against answer
    return option?.key?.toLowerCase().includes(value?.toLowerCase());
  };
  return (
      <Autocomplete
        autoComplete='question'
        label={''}
        labelHidden={false}
        isLoading={false} 
        descriptiveText=" Pick a question by entering keywords. Questions will be 
                        filtered by the options above.
                        Clear the field to start searching again."
        ref={ref}
        name={props.name}
        placeholder={props.placeholder}
        alignSelf="stretch"
        size="large"
        style={{ width: "300px" }}
        inputStyles={{width: "100%"}}
        //backgroundColor="rgba(255, 255, 255, 0.55)"
        selectsize={10}
        isDisabled={false}
        title={title}
        value={value}
        clearButtonLabel="Clear"
        inputMode='search'
        enterKeyHint="Go"
        onChange={(event) => setValue(event.target.key)}
        onClear={() => { 
          setValue('')
          if (props.onClear !== undefined) 
            props.onClear(); 
          }
        }
        options={props.options}
        optionFilter={optionFilter}
        renderOption={renderOptions}
        onSelect={(option) => {
          console.log(option)
          setValue(option?.key);
          setTitle(option?.title);
          if (props.onSelect !== undefined) {
           props.onSelect(option?.id, option?.key, option?.title);
          }
        }}
      />
  );
};
export default AutocompleteField;