/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function AboutPage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "AboutPage")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="1440px"
        height="300px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "WHY")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Left38962003")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HeroMessage38962004")}
          >
            <Flex
              gap="30px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Message38962005")}
            >
              <Text
                fontFamily="Roboto"
                fontSize="32px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="60px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Informed Voters, Accountable Candidates"
                {...getOverrideProps(overrides, "Heading38962006")}
              ></Text>
              <Text
                fontFamily="Roboto"
                fontSize="24px"
                fontWeight="400"
                textIndent="10px"
                color="rgba(48,64,80,1)"
                lineHeight="36px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.57px"
                width="660px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="More informed and active voters.&#xA;More honest and responsive candidates.&#xA;A more democratic and representative system."
                {...getOverrideProps(overrides, "Body38962007")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="300px"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Right38962008")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="/why.png"
            {...getOverrideProps(overrides, "image38962009")}
          ></Image>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="1440px"
        height="300px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HOW")}
      >
        <Flex
          gap="0"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Left38962011")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="/how.png"
            {...getOverrideProps(overrides, "image38962012")}
          ></Image>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Right38962013")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="660px"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HeroMessage38962014")}
          >
            <Flex
              gap="30px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              grow="1"
              shrink="1"
              basis="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Message38962015")}
            >
              <Text
                fontFamily="Roboto"
                fontSize="32px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="60px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Honest and Reliable Answers"
                {...getOverrideProps(overrides, "Heading38962016")}
              ></Text>
              <Text
                fontFamily="Roboto"
                fontSize="24px"
                fontWeight="400"
                textIndent="10px"
                color="rgba(48,64,80,1)"
                lineHeight="36px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.57px"
                width="660px"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Answer voters’ questions with facts and respect.&#xA;No attacks on other candidates, only decency.&#xA;Be truthful, transparent on important issues."
                {...getOverrideProps(overrides, "Body38962017")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="1440px"
        height="300px"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "WHAT")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="720px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="30px 30px 30px 30px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Left38962019")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "HeroMessage38962020")}
          >
            <Flex
              gap="30px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Message38962021")}
            >
              <Text
                fontFamily="Roboto"
                fontSize="32px"
                fontWeight="600"
                color="rgba(13,26,38,1)"
                lineHeight="30px"
                textAlign="center"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="60px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Townhall On Steroids"
                {...getOverrideProps(overrides, "Heading38962022")}
              ></Text>
              <Text
                fontFamily="Roboto"
                fontSize="24px"
                fontWeight="400"
                textIndent="10px"
                color="rgba(48,64,80,1)"
                lineHeight="36px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.57px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Well researched answers with references&#xA;Questions & Answers created by volunteers&#xA;Eventually driven by LLM, once there is enough data"
                {...getOverrideProps(overrides, "Body38962023")}
              ></Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Right38962024")}
        >
          <Image
            width="unset"
            height="unset"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src="/what.png"
            {...getOverrideProps(overrides, "image38962025")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}
