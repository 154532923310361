import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports.js';  
import './index.scss';
import "@aws-amplify/ui-react/styles.css";
import studioTheme from "./ui-components/studioTheme.js";
import React from 'react';
import { createRoot } from 'react-dom/client';
import {ThemeProvider, Authenticator} from '@aws-amplify/ui-react';
import App from './components/App.js';


Amplify.configure(awsconfig)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Authenticator.Provider>
        <ThemeProvider theme={studioTheme}>
                <App/>
        </ThemeProvider>
    </Authenticator.Provider>
);


