/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function NavBarHeader(props) {
  const { overrides, ...rest } = props;
  const homeOnClick = useNavigateAction({ type: "url", url: "/home/" });
  const askVivekOnClick = useNavigateAction({ type: "url", url: "/askvivek/" });
  const chatOnClick = useNavigateAction({ type: "url", url: "/chat/" });
  const aboutOnClick = useNavigateAction({ type: "url", url: "/about/" });
  const contactOnClick = useNavigateAction({ type: "url", url: "/contact/" });
  const buttonThreeNineFourNineThreeFourSixSixOnClick = useNavigateAction({
    type: "url",
    url: "/signin/",
  });
  const buttonThreeNineOneOneFiveSixEightOnClick = useNavigateAction({
    type: "url",
    url: "/signout/",
  });
  return (
    <Flex
      gap="318px"
      direction="row"
      width="1440px"
      height="80px"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.10000000149011612)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "NavBarHeader")}
      {...rest}
    >
      <Flex
        gap="45px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 21px 0px 21px"
        {...getOverrideProps(overrides, "links")}
      >
        <Image
          width="139px"
          height="37px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="unset"
          src="/LogoWithText.png"
          onClick={() => {
            homeOnClick();
          }}
          {...getOverrideProps(overrides, "Home")}
        ></Image>
        <Text
          fontFamily="Segoe Print"
          fontSize="24px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Ask Vivek"
          onClick={() => {
            askVivekOnClick();
          }}
          {...getOverrideProps(overrides, "AskVivek")}
        ></Text>
        <Text
          fontFamily="Segoe Print"
          fontSize="24px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Chat"
          onClick={() => {
            chatOnClick();
          }}
          {...getOverrideProps(overrides, "Chat")}
        ></Text>
        <Text
          fontFamily="Segoe Print"
          fontSize="24px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="About"
          onClick={() => {
            aboutOnClick();
          }}
          {...getOverrideProps(overrides, "About")}
        ></Text>
        <Text
          fontFamily="Segoe Print"
          fontSize="24px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="36px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Contact"
          onClick={() => {
            contactOnClick();
          }}
          {...getOverrideProps(overrides, "Contact")}
        ></Text>
      </Flex>
      <Flex
        gap="34px"
        direction="row"
        width="188px"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "actions")}
      >
        <Button
          width="unset"
          height="unset"
          padding="5px 4px 5px 4px"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Login"
          onClick={() => {
            buttonThreeNineFourNineThreeFourSixSixOnClick();
          }}
          {...getOverrideProps(overrides, "Button39493466")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          justifyContent="flex-end"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="default"
          children="Logout"
          onClick={() => {
            buttonThreeNineOneOneFiveSixEightOnClick();
          }}
          {...getOverrideProps(overrides, "Button3911568")}
        ></Button>
      </Flex>
    </Flex>
  );
}
