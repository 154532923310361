/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserMessages = /* GraphQL */ `
  query GetUserMessages($id: ID!) {
    getUserMessages(id: $id) {
      id
      message
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      userMessagesUserId
      __typename
    }
  }
`;
export const listUserMessages = /* GraphQL */ `
  query ListUserMessages(
    $filter: ModelUserMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        createdAt
        updatedAt
        userMessagesUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestionType = /* GraphQL */ `
  query GetQuestionType($id: ID!) {
    getQuestionType(id: $id) {
      id
      qType
      qTypeDesc
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestionTypes = /* GraphQL */ `
  query ListQuestionTypes(
    $filter: ModelQuestionTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        qType
        qTypeDesc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVoterType = /* GraphQL */ `
  query GetVoterType($id: ID!) {
    getVoterType(id: $id) {
      id
      voterType
      voterDescription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVoterTypes = /* GraphQL */ `
  query ListVoterTypes(
    $filter: ModelVoterTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVoterTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      locationName
      stateNames
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReference = /* GraphQL */ `
  query GetReference($id: ID!) {
    getReference(id: $id) {
      id
      refName
      refUrl
      refDesc
      refType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReferences = /* GraphQL */ `
  query ListReferences(
    $filter: ModelReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        refName
        refUrl
        refDesc
        refType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      answer
      addedBy
      questionType {
        id
        qType
        qTypeDesc
        createdAt
        updatedAt
        __typename
      }
      voterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      reference {
        id
        refName
        refUrl
        refDesc
        refType
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      questionQuestionTypeId
      questionVoterTypeId
      questionLocationId
      questionReferenceId
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        addedBy
        createdAt
        updatedAt
        questionQuestionTypeId
        questionVoterTypeId
        questionLocationId
        questionReferenceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResponses = /* GraphQL */ `
  query GetResponses($id: ID!) {
    getResponses(id: $id) {
      id
      response
      responseDate
      Question {
        id
        question
        answer
        addedBy
        createdAt
        updatedAt
        questionQuestionTypeId
        questionVoterTypeId
        questionLocationId
        questionReferenceId
        __typename
      }
      User {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      createdAt
      updatedAt
      responsesQuestionId
      responsesUserId
      __typename
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        response
        responseDate
        createdAt
        updatedAt
        responsesQuestionId
        responsesUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      VoterType {
        id
        voterType
        voterDescription
        createdAt
        updatedAt
        __typename
      }
      Location {
        id
        locationName
        stateNames
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      userVoterTypeId
      userLocationId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        createdAt
        updatedAt
        userVoterTypeId
        userLocationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
