/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
import React, { useEffect } from "react";
import { SelectField } from "@aws-amplify/ui-react";
import * as queries from "../graphql/queries.js";

/*
  the props for this are:
    label: the label for the field
    name: the name of the field
    defaultValue: the default value for the field
    options: the options for the field
    onSelect: the function to call when an option is selected
*/
const FilterField = (props) => {
  const [key, setKey] = React.useState();
  const [value, setValue] = React.useState();
  const [title, setTitle] = React.useState();
  const [options, setOptions] = React.useState(props?.options);
  const ref = React.createRef();
 // console.log(`FilterField: ${props?.options[0]}`)


  const setFieldValue = (value) => {
    //console.log(`setFieldValue: ${value} passed in`)
    const selectedOption =  props?.options.find(rec => rec.value === value);
    if (selectedOption !== undefined) {
      //console.log(`options: ${props?.options} , ${selectedOption} found`)
      setKey(selectedOption?.key);
      setValue(selectedOption?.value);
      setTitle(selectedOption.title);
      props?.onSelect(selectedOption.key, selectedOption.value, selectedOption.title);
    } 
  }

  const opts = props?.options.map((rec) => {
    return (<option key={rec.key}
      title={rec.title}
      value={rec.value}>{rec.value}
    </option>)
  });
  
  useEffect(() => {
     setFieldValue(props?.defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.defaultValue, props?.options]);



  return (
    <>
      <style>
        {`
          /* Style the tooltip */
          [title]:hover::after {
            content: attr(title);
            background-color: black;
            color: white;
            padding: 4px;
            border-radius: 4px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            z-index: 1;
          }
        `}
      </style>
      <SelectField
        label={props?.label}
        width="300px"
        name={props?.name}
        ref={ref}
        isRequired={true}
        shrink="0"
        size="large"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        value={value}
        onChange={(event) =>  {
            console.log(`onSelect: ${event.target.value} passed in`)
            setFieldValue(event.target.value)
//            props?.onSelect(option.key, option.value, option.title);
        }}
        defaultValue={props?.defaultValue}
        title={title}
        style={{ position: "relative" }}
      >{opts}
        </SelectField>
      </>
  );
};
export default FilterField;
