import React from 'react';    
import { Authenticator } from '@aws-amplify/ui-react';
import {ChatPage  } from '../ui-components/';
import { VGPTNavBar } from './VGPTNavBar';
export function Chat() {
  return (
    <>
      <Authenticator.Provider>
        <VGPTNavBar />
      </Authenticator.Provider>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          padding: 0,
          gap: 0,
        }}
      > <img
      src="/comingsoon.png" width='200px' height='100px'
      alt="" style={{ position: "fixed", top: 100,  zIndex: 2 }}
       />
   
      <ChatPage style={{ position: "relative", top: 0, left: 0, margin: "0 auto", zIndex: 1 }}   
          overrides={{
            Heading: {
              fontSize: "2rem",
              fontWeight: "bold",
              children: "Questions You Can Ask",
            },
            Body: {
              fontSize: "1rem",
              fontWeight: "bold",
              lineHeight: "1.6rem",
            },
            image: { src: "/raisedhand.jpeg" },
          }}
        />
      </div>
    </>
  );
}
export default Chat;