/* eslint-disable no-unused-vars */
import React from 'react';
import {useAuthenticator} from '@aws-amplify/ui-react';
import { VGPTNavBar } from './VGPTNavBar';
import { Login } from './Login';
import AskVivekPage from './AskVivekPage';
import { Navigate } from 'react-router-dom';


export const AskVivek = () => {
    const { user } = useAuthenticator();
    const userEmail = user?.attributes?.email

    console.log(userEmail, user)
    if (userEmail === "" | userEmail === undefined | userEmail === null)
        return (
            <>
                <Login initialState="signin" fromPath="/askvivek/" />
            </>)

    return (
        <>
            <VGPTNavBar user userEmail />
            <AskVivekPage user={user} userEmail={userEmail} />
        </>
    )
}