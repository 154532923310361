import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { NavBarHeader } from "../ui-components/";
import { useLocation } from "react-router-dom";

// Example usage:

export function VGPTNavBar() {
    const location = useLocation();

    const {authStatus}  = useAuthenticator();
    const showLogin = (authStatus === "unauthenticated") || (location.pathname === "/signin/"   );
    console.log(`showLogin: ${showLogin} authStatus: ${authStatus} location.pathname: ${location.pathname}`);

    if (authStatus === "authenticated") 
        return (
            <NavBarHeader 
                    overrides={{
                    Button39493466: { display: "none" },
                    Button3911568: { display: "flex" },
                    Chat: { display: "none" },
                }}
            />
        );

    return (
        <NavBarHeader 
                overrides={{
                Button39493466: { display: "flex" },
                Button3911568: { display: "none" },
                Chat: { display: "none" },
            }}
        />
    );
}
