import  React from 'react';
import QuestionTypeCreateForm from '../ui-components/QuestionTypeCreateForm';
import { gql,ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import awsconfig from '../aws-exports'

ApolloClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

const httpLink = createHttpLink({
  uri: awsconfig.aws_appsync_graphqlEndpoint,
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'x-api-key': awsconfig.aws_appsync_apiKey,
      'x-amz-auth-type': awsconfig.aws_appsync_authenticationType,
      'Access-Control-Allow-Origin': ['https://www.vivek2024gpt.com','https://localhost:3000',
                                     'https://vivek2024gpt.com','https://chat.vivek2024gpt.com'],
    
    }
  }));

const client = new ApolloClient({ 
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const ADD_QUESTION_TYPE = gql`
  mutation AddQuestionType($qType: String!, $qTypeDesc: String!) {
    addQuestionType(qType: $qType, qTypeDesc : $qTypeDesc) {
      id
      qType 
      qTypeDesc
    }
  }
`;

export function AddQuestionType() {

    return (
        <QuestionTypeCreateForm width="800px" client={client} mutation={ADD_QUESTION_TYPE}  />
   );
  }

  export default AddQuestionType;